dmx.Formatters('global', {

    // json(obj:Any):String
    json: function(obj) {
        return JSON.stringify(obj);
    },

    // log(obj:Any):Any
    log: function(obj) {
        console.log(obj);
        return obj;
    },

    // run(name:String, param:Any):Any
    run: function(action, param) {
        if (dmx.__actions[action]) {
            action = dmx.__actions[action];
        }

        if (!action) return;
        
        var scope = dmx.DataScope({
            $param: param
        }, this);

        (function run(action, scope) {
            if (!action) return;

            if (Array.isArray(action)) {
                action.forEach(function(action) {
                    run(action, scope);
                });
                return;
            }

            if (typeof action == 'string') {
                dmx.parse(action, scope);
                return;
            }

            if (action.if) {
                if (dmx.parse(action.if, scope)) {
                    run(action.then, scope);
                } else {
                    run(action.else, scope);
                }
            }

            if (action.repeat) {
                var repeat = dmx.repeatItems(dmx.parse(action.repeat) || action.repeat);
                
                repeat.forEach(function(data) {
                    run(action.exec, dmx.DataScope(data, scope));
                });
            }
        })(action, scope);
    }

});
